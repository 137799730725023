<template>
  <div>
    <div class="binnerimg">
      <img class="binner" src="../../assets/imgs/06.jpg" />
    </div>

    <h1>fanan</h1>
    <h1>fanan</h1>
    <h1>fanan</h1>
    <h1>fanan</h1>
    <h1>fanan</h1>
    <h1>fanan</h1>
    <h1>fanan</h1>
    <h1>fanan</h1>
    <h1>fanan</h1>
    <h1>fanan</h1>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
